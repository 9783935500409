<template>
  <div>
    <Loader :visible="isLoading" />
    <div class="my-projects" v-if="initProjects">
      <v-row v-if="!projects.length">
        <v-col offset-md="4" md="4">
          <v-img class="main-img" :src="require(`@/assets/img/projects.svg`)" contain max-height="630" />
        </v-col>
      </v-row>
      <v-row v-if="!projects.length">
        <v-col offset-md="2" md="8">
          <h2 class="tway-header-1 mb-4">¡Levanta tu proyecto y comienza la Transformación de tu empresa!</h2>
          <div class="tway-header-2 tway-gray-dark--text description-test mb-4">
            Puedes encontrar el producto o servicio validado y a la medida para reducir tus brechas digitales.
            Completando este simple formulario con la información esencial de tu proyecto recibirás las recomendaciones
            rankeadas y de alta precisión con los mejores proveedores para las necesidades de tu negocio.
          </div>
        </v-col>
      </v-row>
      <v-btn
        v-if="!projects.length"
        large
        depressed
        rounded
        class="tway-violet white--text roboto elevation-3"
        @click="createProject"
      >
        + Crear Proyecto
      </v-btn>
      <v-row v-if="projects.length" justify="space-between">
        <div class="t-tway ml-2">Mis Proyectos</div>
        <div>
          <v-btn
            v-if="desktop"
            large
            depressed
            rounded
            class="tway-violet white--text roboto elevation-3"
            @click="createProject"
          >
            <v-icon medium class="mr-2"> mdi-plus </v-icon>
            Crear Proyecto
          </v-btn>
        </div>
      </v-row>
      <v-row v-if="projects.length">
        <v-tabs color="tway-violet">
          <v-tab
            @click="
              () => {
                statusSelected = index;
              }
            "
            v-show="project"
            v-for="(project, index) in projectsTabs"
            :key="index"
            class="text-capitalize"
          >
            {{ project }}
          </v-tab>
        </v-tabs>
      </v-row>
      <v-container fluid v-if="projects.length">
        <v-row dense>
          <v-col
            v-for="(project, indexProject) in projectsStatus"
            :key="indexProject"
            cols="12"
            xs="12"
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <v-card>
              <v-card-title>
                <span v-if="!project.name"> Sin Registro</span>
                <span v-if="project.name">{{ project.name.substring(0, 20) }}</span>

                <v-spacer />

                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      :disabled="
                        project.status.description == 'Creado' || project.status.description == 'Incompleto'
                          ? false
                          : true
                      "
                      @click="editar(project.id)"
                    >
                      <v-list-item-title class="roboto pa-0">
                        <v-icon color="tway-violet" class="mr-3"> mdi-pencil </v-icon>Editar
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="detalle(project.id)">
                      <v-list-item-title class="roboto pa-0">
                        <v-icon color="tway-violet" class="mr-3"> mdi-eye </v-icon>Ver Detalle
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      :disabled="
                        project.status.description == 'Creado' || project.status.description == 'Incompleto'
                          ? false
                          : true
                      "
                      v-show="project.status.description == 'Creado' || project.status.description == 'Incompleto'"
                      @click="deletePro(project.id)"
                    >
                      <v-list-item-title class="roboto">
                        <v-icon color="tway-violet" class="mr-3"> mdi-delete </v-icon>Eliminar
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-show="project.status.description == 'Publicado'" @click="publish(project.id, true)">
                      <v-list-item-title class="roboto">
                        <v-icon color="tway-violet" class="mr-3"> mdi-pencil </v-icon>Modificar Fechas
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-show="
                        project.status.description == 'Publicado' ||
                        project.status.description == 'En proceso de licitación'
                      "
                      @click="cancel(project.id)"
                    >
                      <v-list-item-title class="roboto">
                        <v-icon color="tway-violet" class="mr-3"> mdi-close-circle-outline </v-icon>Cancelar Proyecto
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-title>

              <v-card-actions class="py-0">
                <v-list style="max-width: 100%" class="transparent py-0">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="#7319D5"> mdi-clock-time-four-outline </v-icon>
                    </v-list-item-icon>
                    <v-list-item-subtitle v-if="project.period" class="card-text">
                      {{ project.period.name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="!project.period" class="card-text"> Sin Registro </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="#7319D5"> mdi-chart-line </v-icon>
                    </v-list-item-icon>
                    <v-list-item-subtitle class="card-text">
                      Impacto en
                      <span class="font-weight-bold">{{ project.dimension.name }}</span>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon class="card-text">
                      <v-icon color="#7319D5"> mdi-crosshairs </v-icon>
                    </v-list-item-icon>
                    <v-list-item-subtitle class="card-text">
                      Impacto en
                      <span class="font-weight-bold">{{ project.subDimension.name }}</span>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon class="card-text">
                      <v-img :src="require(`@/assets/img/pmo/carbon_money.png`)" color="#7319D5" />
                    </v-list-item-icon>
                    <v-list-item-subtitle class="card-text">
                      Presupuesto Estimado
                      <span
                        :class="project.estimatedBudged ? 'font-weight-bold' : ''"
                        :style="!project.estimatedBudged ? 'color: #b71c1c' : ''"
                        >{{ project.estimatedBudged ? project.estimatedBudged.value : "Sin registro" }}
                        {{ project.estimatedBudged ? (company.countryCode == "CL" ? "UF" : "") : "" }}</span
                      >
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title class="card-text"> Resumen Ejecutivo </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <div v-if="!project.summary" style="color: #b71c1c" class="resumen-text text-justify">
                      Sin registro
                    </div>
                    <div v-if="project.summary" class="resumen-text text-justify">
                      {{ project.summary }}
                    </div>
                  </v-list-item>
                  <v-list-item>
                    <div
                      v-if="project.status.description == 'En proceso de licitación'"
                      class="border status-lic bpp mt-2"
                    >
                      PROYECTO EN PROCESO DE LICITACIÓN
                    </div>
                    <div v-if="project.status.description == 'Publicado'" class="border status byc mt-2">
                      PROYECTO EN PROCESO DE POSTULACIÓN
                    </div>
                    <div v-if="project.status.description == 'Creado'" class="status-complete border bgc mt-2">
                      PROYECTO LISTO PARA PUBLICAR
                    </div>
                    <div v-if="project.status.description === 'Incompleto'" class="brp border letter mt-2">
                      PROYECTO CON INFORMACIÓN INCOMPLETA
                    </div>
                  </v-list-item>
                  <v-list-item class="py-3">
                    <v-btn
                      v-if="project.status.id == 3"
                      outlined
                      rounded
                      text
                      class="border-button-violet"
                      style="border: 1px solid #7319d5; box-sizing: border-box; border-radius: 30px"
                      :to="{
                        name: 'applications',
                        params: { projectId: project.id },
                      }"
                      color="tway-violet"
                      @click="goToStats(project.id)"
                    >
                      VER POSTULACIONES
                    </v-btn>
                    <v-btn
                      v-if="project.status.description == 'Creado'"
                      outlined
                      rounded
                      text
                      class="border-button-violet"
                      style="border: 1px solid #7319d5; box-sizing: border-box; border-radius: 30px"
                      color="tway-violet"
                      @click="publish(project.id, false)"
                    >
                      PUBLICAR PROYECTO
                    </v-btn>
                    <v-btn
                      v-if="project.status.description === 'Incompleto'"
                      outlined
                      rounded
                      text
                      class="border-button-violet"
                      style="border: 1px solid #7319d5; box-sizing: border-box; border-radius: 30px"
                      color="tway-violet"
                      @click="editar(project.id)"
                    >
                      SEGUIR COMPLETANDO
                    </v-btn>
                    <v-btn
                      v-if="project.status.id == 4 || project.status.id == 5"
                      outlined
                      rounded
                      text
                      class="border-button-violet"
                      style="border: 1px solid #16c62e; box-sizing: border-box; border-radius: 30px"
                      color="tway-apple-green"
                      :to="{
                        name: 'applications',
                        params: { projectId: project.id },
                      }"
                    >
                      VER SEGUIMIENTO
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog v-model="dialog" scrollable max-width="733px">
        <v-form ref="form" v-model="valid">
          <v-card>
            <div v-if="!editModal" class="text-h6 ml-2 pa-2">Publicar Proyecto</div>
            <div v-if="editModal" class="text-h6 ml-2 pa-2">Modificar Fechas para Proyecto</div>
            <div v-if="!editModal" class="roboto ml-2 mb-2 pa-2">
              Para la publicación de este proyecto, necesitamos que indiques y declares lo siguiente:
            </div>
            <div v-if="editModal" class="roboto ml-2 mb-2 pa-2">
              Aquí puedes modificar las fechas que indicaste anteriormente.
            </div>
            <v-card-text>
              <div class="modal-title pa-0 ma-0">Fecha de período de Postulación</div>
              <v-row>
                <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
                  <v-menu
                    v-model="fechaInicio"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="publicationPeriod.startDate"
                        label="Fecha de Inicio"
                        :rules="[(v) => !!v || 'Campo Fecha de Inicio es requerido']"
                        :error-messages="errorPublicationDateStart()"
                        placeholder="dd-mm-aaaa"
                        outlined
                        readonly
                        autocomplete="off"
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        required
                      />
                    </template>
                    <v-date-picker
                      @change="errorDate()"
                      color="tway-violet"
                      v-model="publicationPeriod.startDate"
                      :first-day-of-week="1"
                      @input="fechaInicio = false"
                      :min="new Date().toISOString()"
                    />
                  </v-menu>
                </v-col>
                <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
                  <v-menu
                    v-model="fechaTermino"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="publicationPeriod.endDate"
                        label="Fecha de Término"
                        :rules="[required('Fecha de Término')]"
                        :error-messages="errorPublicationDateEnd()"
                        placeholder="dd-mm-aaaa"
                        outlined
                        readonly
                        autocomplete="off"
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        required
                      />
                    </template>
                    <v-date-picker
                      color="tway-violet"
                      v-model="publicationPeriod.endDate"
                      :first-day-of-week="1"
                      @input="fechaTermino = false"
                      @change="errorDate()"
                      :min="publicationPeriod.startDate ? publicationPeriod.startDate : new Date().toISOString()"
                    />
                  </v-menu>
                </v-col>
              </v-row>
              <div class="modal-title mr-2">Fecha de período de Licitación</div>
              <v-row>
                <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
                  <v-menu
                    v-model="fechaInicioLic"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="biddingPeriod.startDate"
                        label="Fecha de Inicio"
                        :rules="[required('Fecha de Inicio')]"
                        :error-messages="errorBiddingDateStart()"
                        placeholder="dd-mm-aaaa"
                        outlined
                        readonly
                        autocomplete="off"
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        required
                      />
                    </template>
                    <v-date-picker
                      color="tway-violet"
                      v-model="biddingPeriod.startDate"
                      :first-day-of-week="1"
                      @input="fechaInicioLic = false"
                      @change="errorDate()"
                      :min="publicationPeriod.endDate ? publicationPeriod.endDate : new Date().toISOString()"
                    />
                  </v-menu>
                </v-col>
                <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
                  <v-menu
                    v-model="fechaTerminoLic"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="biddingPeriod.endDate"
                        label="Fecha de Término"
                        :rules="[required('Fecha de Término')]"
                        :error-messages="errorBiddingDateEnd()"
                        placeholder="dd-mm-aaaa"
                        outlined
                        readonly
                        autocomplete="off"
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        required
                      />
                    </template>
                    <v-date-picker
                      color="tway-violet"
                      v-model="biddingPeriod.endDate"
                      :first-day-of-week="1"
                      @input="fechaTerminoLic = false"
                      @change="errorDate()"
                      :min="biddingPeriod.startDate ? biddingPeriod.startDate : new Date().toISOString()"
                    />
                  </v-menu>
                </v-col>
              </v-row>
              <div class="modal-title mr-2">Fecha de Resultados</div>
              <v-row>
                <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
                  <v-menu
                    v-model="fechaResultados"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateOfResults"
                        label="Fecha de resultados"
                        :rules="[required('Fecha de resultados')]"
                        placeholder="dd-mm-aaaa"
                        outlined
                        readonly
                        autocomplete="off"
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        required
                      />
                    </template>
                    <v-date-picker
                      @change="errorDate()"
                      color="tway-violet"
                      v-model="dateOfResults"
                      :first-day-of-week="1"
                      @input="fechaResultados = false"
                      :min="biddingPeriod.endDate ? biddingPeriod.endDate : new Date().toISOString()"
                    />
                  </v-menu>
                </v-col>
              </v-row>
              <div v-if="!editModal" class="modal-title pa-0 ma-0">Declaraciones</div>
              <v-checkbox v-if="!editModal" color="success" v-model="declaration">
                <template v-slot:label>
                  <div>
                    <a class="checkbox-modal">
                      Declaro que la información entregada es verídica y se ajusta a las necesidades de la empresa.
                      Cualquier información errónea o imprecisa será de exclusiva responsabilidad del declarante.
                    </a>
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox v-if="!editModal" color="success" v-model="declaration2">
                <template v-slot:label>
                  <div>
                    <a class="checkbox-modal">
                      Autorizo a Tway para que la información y los datos proporcionados en este formulario sean
                      tratados y compartidos en el proceso de licitación de los planes Business y Best Fit. Tway no
                      responderá frente al tratamiento de la información que puedan realizar terceros.
                    </a>
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox v-if="!editModal" color="success" v-model="acceptTermsAndConditions">
                <template v-slot:label>
                  <div>
                    <a target="_blank" class="violet-text text-decoration-underline">
                      Acepto los términos y condiciones de uso
                    </a>
                  </div>
                </template>
              </v-checkbox>
            </v-card-text>
            <v-card-actions>
              <v-col class="text-center">
                <v-btn
                  color="deep-purple accent-4"
                  class="roboto text-right roboto mr-6"
                  text
                  @click="(dialog = false), refresh()"
                >
                  CANCELAR
                </v-btn>
                <v-btn
                  large
                  rounded
                  color="tway-violet"
                  class="elevation-2 pa-4 white--text roboto"
                  :disabled="!canSubmit"
                  @click="publishProject()"
                  v-if="!editModal"
                >
                  PUBLICAR PROYECTO
                </v-btn>
                <v-btn
                  large
                  rounded
                  color="tway-violet"
                  :disabled="!toEdit"
                  class="elevation-2 pa-4 white--text roboto"
                  @click="editPublishProject()"
                  v-if="editModal"
                >
                  GUARDAR CAMBIOS
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog v-model="modalCreated" max-width="500">
        <v-card :class="desktop ? 'pa-10' : 'p-2'" style="border-radius: 4px !important">
          <v-card-title class="headline mb-3">
            <v-row class="ma-0 pa-0" justify="center">
              <v-img :src="require('@/assets/img/check.svg')" class="rounded" max-width="25%" />
            </v-row>
          </v-card-title>

          <v-card-text class="headline tway-gray-dark--text mb-4" style="font-size: 18px !important">
            <v-row class="ma-0 pa-0 text-center" justify="center">
              <span v-if="!editModal" class="text-center"> Tu proyecto se ha publicado exitosamente </span>
              <span v-if="editModal" class="text-center"> Tus cambios han sido guardados! </span>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-row class="ma-0 pa-0" justify="center">
              <v-col cols="12">
                <v-row class="pa-0 ma-0" justify="center">
                  <v-btn class="roboto white--text" rounded color="tway-violet" @click.stop="modalCreated = false">
                    ACEPTAR
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="modalDelete" max-width="500">
        <v-card :class="desktop ? 'pa-10' : 'p-2'" style="border-radius: 4px !important">
          <v-card-title
            class="headline mb-3"
            :class="!desktop ? 'pt-8' : ''"
            :style="!desktop ? 'font-size: 20px !important;' : ''"
          >
            <v-row class="ma-0 pa-0" justify="center">
              <span class="text-center">¿Realmente quieres eliminar este proyecto?</span>
            </v-row>
            <v-row class="ma-0 pa-0" justify="center" v-if="deleteSuccesful">
              <v-icon color="#16C62E" size="95"> mdi-check-circle </v-icon>
            </v-row>
          </v-card-title>

          <v-card-text class="headline tway-gray-dark--text mb-4" style="font-size: 18px !important">
            <v-row class="ma-0 pa-0 modal-text" justify="center" v-if="!deleteSuccesful">
              <span> Al eliminar este Proyecto, se eliminarán los datos registrados y no podrás publicarlo. </span>
            </v-row>
            <v-row class="ma-0 pa-0 text-center" justify="center" v-if="deleteSuccesful">
              <span> Tu Proyecto se ha eliminado exitosamente. </span>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-col v-if="desktop && !deleteSuccesful" class="text-center">
              <a
                style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                class="tway-violet--text roboto"
                v-if="desktop"
                :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
                @click.stop="modalDelete = false"
              >
                VOLVER
              </a>
              <v-btn
                style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                v-if="desktop"
                rounded
                small
                color="tway-violet"
                class="elevation-2 pa-6 mb-2 white--text roboto"
                @click="deleteProject(deleteProjectId)"
              >
                SI, ELIMINAR PROYECTO
              </v-btn>
            </v-col>
            <v-col v-if="desktop && deleteSuccesful" class="text-center">
              <v-btn
                style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                v-if="desktop"
                large
                rounded
                color="tway-violet"
                class="elevation-2 pa-6 mb-2 white--text roboto"
                @click="(modalDelete = false), (deleteSuccesful = false)"
              >
                ACEPTAR
              </v-btn>
            </v-col>
            <v-row v-if="!desktop && !deleteSuccesful" class="ma-0 pa-0" justify="center">
              <v-col cols="12">
                <v-row class="pa-0 ma-0" justify="center">
                  <v-btn
                    style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                    rounded
                    small
                    color="tway-violet"
                    class="elevation-2 pa-6 white--text roboto"
                    @click="deleteProject(deleteProjectId)"
                  >
                    SI, ELIMINAR PROYECTO
                  </v-btn>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row class="pa-0 ma-0" justify="center">
                  <a
                    style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                    class="tway-violet--text roboto"
                    v-if="!desktop"
                    :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
                    @click.stop="(modalDelete = false), (deleteSuccesful = false)"
                  >
                    VOLVER
                  </a>
                </v-row>
              </v-col>
            </v-row>
            <v-col v-if="mobile && deleteSuccesful" class="text-center">
              <v-btn
                style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                v-if="mobile"
                x-large
                rounded
                color="tway-violet"
                class="elevation-2 pa-6 mb-2 white--text roboto"
                @click="modalDelete = false"
              >
                ACEPTAR
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="modalCancel" max-width="500">
        <v-card :class="desktop ? 'pa-10' : 'p-2'" style="border-radius: 4px !important">
          <v-card-title
            class="headline mb-3"
            :class="!desktop ? 'pt-8' : ''"
            :style="!desktop ? 'font-size: 20px !important;' : ''"
          >
            <v-row class="ma-0 pa-0" justify="center" v-if="!cancelSuccesful">
              <span class="text-center">¿Realmente quieres cancelar este proyecto?</span>
            </v-row>
            <v-row class="ma-0 pa-0" justify="center" v-if="cancelSuccesful">
              <v-icon color="#16C62E" size="75"> mdi-check-circle </v-icon>
            </v-row>
          </v-card-title>

          <v-card-text class="headline tway-gray-dark--text mb-4" style="font-size: 18px !important">
            <v-row class="ma-0 pa-0 modal-text" justify="center" v-if="!cancelSuccesful">
              <span>
                Al cancelar este Proyecto, se eliminarán las postulaciones que los Especialistas realizaron y el
                Proyecto pasará al estado “Listo para publicar”.
              </span>
            </v-row>
            <v-row class="ma-0 pa-0 text-center" justify="center" v-if="cancelSuccesful">
              <span> Tu Proyecto se ha cancelado exitosamente. </span>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-col v-if="desktop && !cancelSuccesful" class="text-center">
              <a
                style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                class="tway-violet--text roboto"
                v-if="desktop"
                :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
                @click.stop="modalCancel = false"
              >
                VOLVER
              </a>
              <v-btn
                style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                v-if="desktop"
                rounded
                small
                color="tway-violet"
                class="elevation-2 pa-6 mb-2 white--text roboto"
                @click="cancelProject(cancelProjectId)"
              >
                SI, CANCELAR PROYECTO
              </v-btn>
            </v-col>
            <v-col v-if="desktop && cancelSuccesful" class="text-center">
              <v-btn
                style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                v-if="desktop"
                rounded
                color="tway-violet"
                class="elevation-2 mb-2 white--text roboto"
                @click="(modalCancel = false), (cancelSuccesful = false)"
              >
                ACEPTAR
              </v-btn>
            </v-col>
            <v-row v-if="!desktop && !cancelSuccesful" class="ma-0 pa-0" justify="center">
              <v-col cols="12">
                <v-row class="pa-0 ma-0" justify="center">
                  <v-btn
                    style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                    rounded
                    small
                    color="tway-violet"
                    class="elevation-2 pa-6 white--text roboto"
                    @click="cancelProject(cancelProjectId)"
                  >
                    SI, CANCELAR PROYECTO
                  </v-btn>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row class="pa-0 ma-0" justify="center">
                  <a
                    style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                    class="tway-violet--text roboto"
                    v-if="!desktop"
                    :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
                    @click.stop="(modalCancel = false), (cancelSuccesful = false)"
                  >
                    VOLVER
                  </a>
                </v-row>
              </v-col>
            </v-row>
            <v-col v-if="mobile && cancelSuccesful" class="text-center">
              <v-btn
                style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                v-if="mobile"
                rounded
                color="tway-violet"
                class="elevation-2 pa-6 mb-2 white--text roboto"
                @click="modalCancel = false"
              >
                ACEPTAR
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import CallForTenderService from "@/services/callForTenderService";
import ValidatorsService from "@/services/validatorsService";
import Loader from "@/components/Loader";
import { mapGetters } from "vuex";
export default {
  name: "ThisCompanyProjects",
  beforeRouteUpdate(to, from, next) {
    next();
  },
  components: {
    Loader,
  },
  props: {
    company: {
      default: () => {},
      type: Object,
    },
  },
  data: () => ({
    initProjects: false,
    projects: [],
    toEdit: false,
    projectsContants: [],
    statusSelected: 0,
    dialog: false,
    modalCreated: false,
    isLoading: true,
    fechaInicio: false,
    fechaTermino: false,
    fechaInicioLic: false,
    fechaTerminoLic: false,
    fechaResultados: false,
    publicationPeriod: {
      startDate: "",
      endDate: "",
    },
    biddingPeriod: {
      startDate: "",
      endDate: "",
    },
    dateOfResults: "",
    acceptTermsAndConditions: false,
    declaration: false,
    declaration2: false,
    publishProjectId: "",
    editModal: false,
    modalCancel: false,
    modalDelete: false,
    cancelProjectId: "",
    deleteProjectId: "",
    cancelSuccesful: false,
    deleteSuccesful: false,
    valid: false,
    errorPublicationStartDate: false,
    errorPublicationFinishDate: false,
    errorBiddingStartDate: false,
    errorBiddingFinishDate: false,
  }),
  created() {
    let redirectedProject = this.$route.params.projectId;
    this.getMyProjects().then(() => {
      if (redirectedProject && this.projects && this.projects.find((p) => p.id === redirectedProject)) {
        this.modalCreated = true;
      }
    });
  },
  methods: {
    required: (propertyType) => ValidatorsService.required(propertyType),
    errorDate: function () {
      if (
        this.publicationPeriod.startDate > this.publicationPeriod.endDate &&
        this.publicationPeriod.startDate != "" &&
        this.publicationPeriod.endDate != ""
      ) {
        this.errorPublicationStartDate = true;
      } else {
        this.errorPublicationStartDate = false;
      }

      if (
        this.publicationPeriod.endDate > this.biddingPeriod.startDate &&
        this.publicationPeriod.endDate != "" &&
        this.biddingPeriod.startDate != ""
      ) {
        this.errorPublicationFinishDate = true;
      } else {
        this.errorPublicationFinishDate = false;
      }

      if (
        this.biddingPeriod.startDate > this.biddingPeriod.endDate &&
        this.biddingPeriod.startDate != "" &&
        this.biddingPeriod.endDate != ""
      ) {
        this.errorBiddingStartDate = true;
      } else {
        this.errorBiddingStartDate = false;
      }

      if (
        this.biddingPeriod.endDate > this.dateOfResults &&
        this.biddingPeriod.endDate != "" &&
        this.dateOfResults != ""
      ) {
        this.errorBiddingFinishDate = true;
      } else {
        this.errorBiddingFinishDate = false;
      }
    },
    errorPublicationDateStart() {
      if (this.errorPublicationStartDate) {
        return "Fecha de Inicio no puede ser mayor a Fecha de Término";
      }
    },
    errorPublicationDateEnd() {
      if (this.errorPublicationFinishDate) {
        return "Fecha de Publicación no puede ser mayor a la fecha de Licitación";
      }
    },
    errorBiddingDateStart() {
      if (this.errorBiddingStartDate) {
        return "Fecha de Inicio no puede ser mayor a Fecha de Término";
      }
    },
    errorBiddingDateEnd() {
      if (this.errorBiddingFinishDate) {
        return "Fecha de Licitación no puede ser mayor a la Publicación de resultados";
      }
    },
    publish(id, modal) {
      this.dialog = true;
      this.publishProjectId = id;
      if (modal) {
        this.getProject(id);
        this.editModal = true;
      } else {
        this.editModal = false;
      }
    },
    deletePro(id) {
      this.deleteProjectId = id;
      this.modalDelete = true;
    },
    cancel(id) {
      this.cancelProjectId = id;
      this.modalCancel = true;
    },
    refresh() {
      this.$refs.form.reset();
      this.publicationPeriod.startDate = "";
      this.publicationPeriod.endDate = "";
      this.biddingPeriod.startDate = "";
      this.biddingPeriod.endDate = "";
      this.dateOfResults = "";
    },
    createProject() {
      this.$router.push({ name: "project-foundation" });
    },
    publishProject() {
      if (
        this.$refs.form.validate() &&
        !this.errorPublicationStartDate &&
        !this.errorPublicationFinishDate &&
        !this.errorBiddingStartDate &&
        !this.errorBiddingFinishDate
      ) {
        this.isLoading = true;
        let data = {
          id: this.publishProjectId,
          publicationPeriod: {
            startDate: this.publicationPeriod.startDate,
            endDate: this.publicationPeriod.endDate,
          },
          biddingPeriod: {
            startDate: this.biddingPeriod.startDate,
            endDate: this.biddingPeriod.endDate,
          },
          dateOfResults: this.dateOfResults,
        };
        CallForTenderService.publishProject(data)
          .then(() => {
            this.refresh();
            this.getMyProjects();
          })
          .catch((err) => this.$log.error("Unexpected error: ", err))
          .finally((this.dialog = false), (this.modalCreated = true), this.refresh(), (this.isLoading = false));
      }
    },
    editPublishProject() {
      if (
        this.$refs.form.validate() &&
        !this.errorPublicationStartDate &&
        !this.errorPublicationFinishDate &&
        !this.errorBiddingStartDate &&
        !this.errorBiddingFinishDate
      ) {
        this.isLoading = true;
        let data = {
          id: this.publishProjectId,
          publicationPeriod: {
            startDate: this.publicationPeriod.startDate,
            endDate: this.publicationPeriod.endDate,
          },
          biddingPeriod: {
            startDate: this.biddingPeriod.startDate,
            endDate: this.biddingPeriod.endDate,
          },
          dateOfResults: this.dateOfResults,
        };
        CallForTenderService.editPublishProject(data)
          .then(() => {
            this.refresh();
            this.getMyProjects();
          })
          .catch((err) => this.$log.error("Unexpected error: ", err))
          .finally((this.dialog = false), (this.modalCreated = true), this.refresh(), (this.isLoading = false));
      }
    },
    getProject(id) {
      return CallForTenderService.getProject(id)
        .then((project) => {
          this.publicationPeriod.startDate = project.publicationPeriod.startDate.split("T")[0];
          this.publicationPeriod.endDate = project.publicationPeriod.endDate.split("T")[0];
          this.biddingPeriod.startDate = project.biddingPeriod.startDate.split("T")[0];
          this.biddingPeriod.endDate = project.biddingPeriod.endDate.split("T")[0];
          this.dateOfResults = project.dateOfResults.split("T")[0];
          setTimeout(() => {
            this.toEdit = false;
          }, 500);
        })
        .catch((err) => this.$log.error("Unexpected error: ", err));
    },
    getMyProjects() {
      return CallForTenderService.getProjects()
        .then((projects) => {
          this.projects = projects;

          let published = this.projects.filter((e) => e.status.id == 3);
          published.forEach((p) => {
            // una llamada por cada proyecto publicado
            // no sirve revisar los que estan creados ni los que ya se les realizó el mach
            CallForTenderService.getMatchList(p.id)
              .then((response) => {
                // response.status.id -> numero
                // response.status.description -> texto
                this.projects = this.projects.map((e) => {
                  if (e.id == p.id) {
                    e.status = response.status;
                  }
                  return e;
                });
              })
              .catch((err) => {
                console.log(err);
              });
          });
        })
        .catch((err) => this.$log.error("Unexpected error: ", err))
        .then(() => {
          this.isLoading = false;
          this.initProjects = true;
        });
    },
    deleteProject(id) {
      CallForTenderService.deleteProject(id)
        .then(() => {
          this.projects = this.projects.filter((e) => e.id != id);
          this.deleteSuccesful = true;
          this.getMyProjects();
        })
        .catch((err) => this.$log.error("Unexpected error: ", err));
    },
    cancelProject: function (id) {
      CallForTenderService.cancelProject(id)
        .then(() => {
          this.cancelSuccesful = true;
          this.getMyProjects();
        })
        .catch((err) => this.$log.error("Unexpected error: ", err));
    },
    detalle(id) {
      this.$router.push(`create-project/${id}/Confirmation`);
    },
    editar(id) {
      this.$router.push(`create-project/${id}/GeneralData`);
    },
    goToStats(projectId) {
      this.$router.push(`project-detail/${projectId}`);
    },
  },
  watch: {
    "publicationPeriod.endDate"() {
      this.toEdit = true;
    },
    "publicationPeriod.startDate"() {
      this.toEdit = true;
    },
    "biddingPeriod.endDate"() {
      this.toEdit = true;
    },
    "biddingPeriod.startDate"() {
      this.toEdit = true;
    },
    dateOfResults() {
      this.toEdit = true;
    },
  },
  computed: {
    ...mapGetters("authentication", ["getCorporative"]),

    projectsStatus() {
      if (this.statusSelected == 0) {
        return this.projects;
      } else {
        if (this.statusSelected == 1) {
          return this.projects.filter((e) => e.status.id == 1 || e.status.id == 2);
        } else if (this.statusSelected == 2) {
          return this.projects.filter((e) => e.status.id == 3);
        } else if (this.statusSelected == 3) {
          return this.projects.filter((e) => e.status.id == 4);
        } else if (this.statusSelected == 4) {
          return this.projects.filter((e) => e.status.id == 5);
        } else {
          return this.projects;
        }
      }
    },
    projectsTabs() {
      var hash = {};

      hash[0] = "Todos";
      hash[1] = "Creados";
      hash[2] = "Publicados";
      hash[3] = "En Proceso de Licitación";
      hash[4] = "En Ejecución";
      return Object.values(hash);
    },
    canSubmit: function () {
      if (!this.acceptTermsAndConditions) {
        return false;
      }

      if (!this.declaration) {
        return false;
      }

      if (!this.declaration2) {
        return false;
      }

      if (!this.valid) {
        return false;
      }
      return true;
    },
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style scoped>
.violet-text {
  color: #7319d5 !important;
}
.main-img {
  margin-top: 10vh;
}
.modal-text {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  text-align: center;
}
.my-projects {
  text-align: center;
}
.tway-header-1 {
  font-weight: 400;
}
.description-test {
  font-weight: 300 !important;
  line-height: 25px !important;
  font-size: 12pt;
  font-family: "Roboto Condensed", sans-serif !important;
}
.t-tway {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 32px;
}
.modal-title {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;

  color: #333333;
}
.tab-text {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height, or 150% */
  display: flex;
  align-items: center;
}
.checkbox-modal {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #000000;
}
.card-text {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #333333;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 10px 0px;
}
.border {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 3px 3px 8px;

  position: static;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  flex: none;
  order: 0;
  align-self: flex-start;
  flex-grow: 0;
  margin: 0px 0px;
}
.bgc {
  background: #e8f5e9;
  border-radius: 4px;
}
.byc {
  background: #fff3e0;
  border-radius: 4px;
}
.brp {
  background: #ffebee;
  border-radius: 4px;
}
.bpp {
  background: #f3e5f5;
  border-radius: 4px;
}
.status-lic {
  position: static;
  left: 3.48%;
  right: 3.48%;
  top: 20%;
  bottom: 20%;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #7319d5;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
}
.letter {
  position: static;
  left: 3.11%;
  right: 3.11%;
  top: 20%;
  bottom: 20%;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #b71c1c;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
}
.status {
  position: static;
  left: 4.76%;
  right: 4.76%;
  top: 20%;
  bottom: 20%;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #f57f17;

  flex: none;
  order: 0;
  align-self: center;
  flex-grow: 0;
  margin: 10px 0px;
}
.status-complete {
  position: static;
  left: 4.76%;
  right: 4.76%;
  top: 20%;
  bottom: 20%;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;

  color: #16c62e;

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  flex: none;
  order: 0;
  align-self: center;
  flex-grow: 0;
  margin: 10px 0px;
}

.resumen-text {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  height: 72px;
  overflow: auto;
  color: #999999;
}
.border-button-violet {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 12px !important;
}
.v-text-field--outlined fieldset {
  border-color: #7319d5 !important;
}
</style>
