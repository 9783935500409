<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" />
    <Loader :visible="isLoading" />
    <SpecialistProjects v-if="getCompany.type === 'SPECIALIST'" @loading="loading" />
    <ClientProjects v-if="getCompany.type === 'CLIENT' && !isLoading" :company="getCompany" />
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import Breadcrumbs from "@/components/Breadcrumbs";
import SpecialistProjects from "@/components/projects/SpecialistProjects";
import ClientProjects from "@/components/projects/ClientProjects";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "MyProjects",

  components: {
    SpecialistProjects,
    ClientProjects,
    Loader,
    Breadcrumbs,
  },

  data: () => ({
    isLoading: true,
    breadcrumbs: [
      {
        text: "Inicio",
        disabled: false,
        exact: true,
        to: {
          name: "home",
        },
      },
      {
        text: "Mis proyectos",
        disabled: true,
        exact: true,
        to: {
          name: "my-projects",
        },
      },
    ],
  }),

  async created() {
    Promise.all([this.setCompanyProfile(), this.surveyComplete(this.getUserId)]).then(() => {
      if (this.percentage != 100) {
        this.$router.push({ path: "/dti-form" });
      }
      this.isLoading = false;
    });
  },
  methods: {
    ...mapActions("CompanyStore", ["setCompanyProfile"]),
    ...mapActions("DigitalTransformationStore", ["surveyComplete"]),
    loading(event) {
      this.isLoading = event;
    },
  },
  computed: {
    ...mapGetters("CompanyStore", ["getCompany"]),
    ...mapGetters("UserStore", ["getUserId"]),
    ...mapGetters("DigitalTransformationStore", ["percentage"]),
  },
};
</script>
