<template>
  <v-breadcrumbs :items="items" class="px-0">
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item :to="item.to" :exact="item.exact" :disabled="item.disabled">
        <span :class="item.disabled ? '' : 'text--tway-violet'">
          {{ item.text }}
        </span>
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: "Breadcrumbs",

  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
