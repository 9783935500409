<template>
  <div>
    <v-row class="project-list" v-if="initProjects">
      <v-col md="11" xs="12">
        <div v-if="!projects.length">
          <v-row>
            <v-col offset-md="4" md="4">
              <v-img class="main-img" :src="require(`@/assets/img/projects-esp.svg`)" contain max-height="60%" />
            </v-col>
          </v-row>
          <v-row>
            <v-col offset-md="2" md="8" style="text-align: center">
              <h2 class="tway-header-1 mb-4">Aún no hay Proyectos publicados en Tway</h2>

              <div class="d-flex justify-center">
                <v-btn large depressed rounded class="tway-violet white--text roboto elevation-3">
                  VER POTENCIALES PROYECTOS
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <div class="main-title ml-5">Proyectos en tway</div>
          <v-tabs color="tway-violet" class="mt-6">
            <v-tab
              @click="
                () => {
                  statusSelected = index;
                }
              "
              v-show="tab"
              v-for="(tab, index) in tabsDef"
              :key="index"
              class="text-capitalize"
            >
              {{ tab.title }}
            </v-tab>
            <v-tab-item v-for="(tab, index) in tabsDef" :key="index">
              <v-container fluid>
                <div>
                  <div v-if="tab.viewType === 'LIST'">
                    <div
                      v-for="(project, indexProject) in projects.filter(tab.filter)"
                      :key="indexProject"
                      class="mt-1"
                    >
                      <v-card class="py-5 d-block">
                        <div class="d-flex justify-space-between">
                          <div class="px-5">
                            <div class="data-label">Nombre del proyecto</div>
                            <div class="text-profile" style="color: #333333">
                              {{ project.name }}
                            </div>
                          </div>
                          <div class="d-flex">
                            <div class="mr-4 date-label">
                              Publicado el
                              {{ formatDate(project.publicationDate) }}
                            </div>
                            <v-card-actions>
                              <v-btn icon @click="project.show = !project.show">
                                <v-icon color="tway-violet" large>
                                  {{ project.show ? "mdi-chevron-up" : "mdi-chevron-down" }}
                                </v-icon>
                              </v-btn>
                            </v-card-actions>
                          </div>
                        </div>
                        <v-expand-transition>
                          <div v-show="project.show">
                            <v-divider class="my-5" />
                            <div class="px-5">
                              <div class="data-label">Objetivos del Negocio</div>
                              <div class="text-profile">
                                {{ project.businessGoal }}
                              </div>
                            </div>
                            <v-divider class="my-5" />
                            <div class="px-5">
                              <div class="data-label">Objetivos del proyecto</div>
                              <div class="text-profile">
                                {{ project.proyectGoal }}
                              </div>
                            </div>
                            <v-divider class="my-5" />
                            <div class="px-5">
                              <div class="data-label">Duración estimada</div>
                              <div class="text-profile">
                                {{ project.period.name }}
                                <br />
                                <v-btn
                                  large
                                  depressed
                                  rounded
                                  class="tway-violet white--text roboto elevation-3 text-upper mt-5"
                                  @click="goToProject(project)"
                                >
                                  Ver detalle de proyecto
                                </v-btn>
                              </div>
                            </div>
                          </div>
                        </v-expand-transition>
                      </v-card>
                    </div>
                  </div>
                  <div v-if="tab.viewType === 'CARDS'">
                    <v-row dense>
                      <v-col
                        v-for="(project, indexProject) in projects.filter(tab.filter)"
                        :key="indexProject"
                        cols="12"
                        xs="12"
                        sm="12"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <v-card>
                          <v-card-title>
                            <span v-if="!project.name"> Sin Registro</span>
                            <span v-if="project.name">{{ project.name.substring(0, 20) }}</span>

                            <v-spacer />
                            <v-menu bottom left>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                  <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                              </template>

                              <v-list>
                                <v-list-item
                                  @click="editar(project.id)"
                                  v-if="project.applicationStatus.id < 8"
                                  :disabled="project.applicationStatus.id != 2"
                                >
                                  <v-list-item-title class="roboto pa-0">
                                    <v-icon color="tway-violet" class="mr-3"> mdi-pencil </v-icon>Editar
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="project.applicationStatus.id < 8" @click="detalle(project.id)">
                                  <v-list-item-title class="roboto pa-0">
                                    <v-icon color="tway-violet" class="mr-3"> mdi-eye </v-icon>Ver Detalle
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                  v-if="project.applicationStatus.id > 2 && project.applicationStatus.id < 5"
                                  @click="cancel(project.id)"
                                >
                                  <v-list-item-title class="roboto">
                                    <v-icon color="tway-violet" class="mr-3"> mdi-close-circle-outline </v-icon>Cancelar
                                    Postulación
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="project.applicationStatus.id == 2" @click="deleteTen(project.id)">
                                  <v-list-item-title class="roboto">
                                    <v-icon color="tway-violet" class="mr-3"> mdi-delete </v-icon>Eliminar
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                  v-if="project.applicationStatus.id == 8 || project.applicationStatus.id == 9"
                                  @click="goToTracking(project)"
                                >
                                  <v-list-item-title class="roboto pa-0">
                                    <v-icon color="tway-violet" class="mr-3"> mdi-eye </v-icon>Ver Resultado
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-card-title>

                          <v-card-actions class="py-0">
                            <v-list class="transparent py-0">
                              <v-list-item>
                                <v-list-item-icon>
                                  <v-icon color="#7319D5"> mdi-clock-time-four-outline </v-icon>
                                </v-list-item-icon>
                                <v-list-item-subtitle class="card-text">
                                  {{ project.period.name }}
                                </v-list-item-subtitle>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-icon>
                                  <v-icon color="#7319D5"> mdi-chart-line </v-icon>
                                </v-list-item-icon>
                                <v-list-item-subtitle class="card-text">
                                  Impacto en
                                  <span class="font-weight-bold">{{ project.dimension.name }}</span>
                                </v-list-item-subtitle>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-icon class="card-text">
                                  <v-icon color="#7319D5"> mdi-crosshairs </v-icon>
                                </v-list-item-icon>
                                <v-list-item-subtitle class="card-text">
                                  Impacto en
                                  <span class="font-weight-bold">{{ project.subDimension.name }}</span>
                                </v-list-item-subtitle>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-icon class="card-text">
                                  <v-img :src="require(`@/assets/img/pmo/carbon_money.png`)" color="#7319D5" />
                                </v-list-item-icon>
                                <v-list-item-subtitle class="card-text">
                                  Presupuesto Estimado
                                  <span
                                    :class="project.estimatedBudged !== null ? 'font-weight-bold' : ''"
                                    :style="!project.estimatedBudged !== null ? 'color: #b71c1c' : ''"
                                    >{{
                                      project.estimatedBudged !== null ? project.estimatedBudged.value : "Sin registro"
                                    }}</span
                                  >
                                </v-list-item-subtitle>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-title class="card-text"> Resumen Ejecutivo </v-list-item-title>
                              </v-list-item>
                              <v-list-item>
                                <div v-if="!project.summary" style="color: #b71c1c" class="resumen-text text-justify">
                                  Sin registro
                                </div>
                                <div v-if="project.summary" class="resumen-text text-justify">
                                  {{ project.summary }}
                                </div>
                              </v-list-item>
                              <v-list-item>
                                <div v-if="project.applicationStatus.id === 5" class="border status-lic bpp mt-2">
                                  EN PROCESO DE LICITACIÓN
                                </div>
                                <div
                                  v-if="project.applicationStatus.id === 4 || project.applicationStatus.id === 3"
                                  class="border status byc mt-2"
                                >
                                  EN PROCESO DE POSTULACIÓN
                                </div>
                                <div v-if="project.applicationStatus.id === 2" class="brp border letter mt-2">
                                  POSTULACIÓN INCOMPLETA
                                </div>
                                <div
                                  v-if="project.applicationStatus.id === 9 || project.applicationStatus.id === 8"
                                  class="brp border letter mt-2"
                                >
                                  <span v-if="project.applicationStatus.id === 9">PROCESO DE POSTULACIÓN FALLIDA</span>
                                  <span v-else>PROCESO DE POSTULACIÓN CANCELADA</span>
                                </div>
                              </v-list-item>
                              <v-list-item class="py-3">
                                <v-btn
                                  v-if="project.applicationStatus.id === 2"
                                  @click="editar(project.id)"
                                  outlined
                                  rounded
                                  text
                                  class="border-button-violet"
                                  style="border: 1px solid #7319d5; box-sizing: border-box; border-radius: 30px"
                                  color="tway-violet"
                                >
                                  COMPLETAR POSTULACIÓN
                                </v-btn>
                                <v-btn
                                  v-if="project.applicationStatus.id >= 3"
                                  @click="goToTracking(project)"
                                  outlined
                                  rounded
                                  text
                                  class="border-button-violet"
                                  style="border: 1px solid #7319d5; box-sizing: border-box; border-radius: 30px"
                                  color="tway-violet"
                                >
                                  <span v-if="project.applicationStatus.id === 9 || project.applicationStatus.id === 8"
                                    >VER RESULTADO</span
                                  >
                                  <span v-else>VER SEGUIMIENTO</span>
                                </v-btn>
                              </v-list-item>
                            </v-list>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-container>
            </v-tab-item>
          </v-tabs>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="modalCreated" max-width="500">
      <v-card :class="desktop ? 'pa-10' : 'p-2'" style="border-radius: 4px !important">
        <v-card-title class="headline mb-3">
          <v-row class="ma-0 pa-0" justify="center">
            <v-img :src="require('@/assets/img/check.svg')" class="rounded" max-width="25%" />
          </v-row>
        </v-card-title>

        <v-card-text class="headline tway-gray-dark--text mb-4" style="font-size: 18px !important">
          <v-row class="ma-0 pa-0 text-center" justify="center">
            <span class="text-center"> Tu Postulación al proyecto se ha realizado exitosamente. </span>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-row class="ma-0 pa-0" justify="center">
            <v-col cols="12">
              <v-row class="pa-0 ma-0" justify="center">
                <v-btn class="roboto white--text" rounded color="tway-violet" @click.stop="modalCreated = false">
                  ACEPTAR
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalDelete" max-width="500">
      <v-card :class="desktop ? 'pa-10' : 'p-2'" style="border-radius: 4px !important">
        <v-card-title
          class="headline mb-3"
          :class="!desktop ? 'pt-8' : ''"
          :style="!desktop ? 'font-size: 20px !important;' : ''"
        >
          <v-row class="ma-0 pa-0" justify="center" v-if="!deleteSuccesful">
            <span class="text-center">¿Realmente quieres eliminar esta Postulación?</span>
          </v-row>
          <v-row class="ma-0 pa-0" justify="center" v-if="deleteSuccesful">
            <v-icon color="#16C62E" size="95"> mdi-check-circle </v-icon>
          </v-row>
        </v-card-title>

        <v-card-text class="headline tway-gray-dark--text mb-4" style="font-size: 18px !important">
          <v-row class="ma-0 pa-0 modal-text" justify="center" v-if="!deleteSuccesful">
            <span>
              Al eliminar este Postulación, se eliminarán los datos registrados y no podrás podras recuperarlos.
            </span>
          </v-row>
          <v-row class="ma-0 pa-0 text-center" justify="center" v-if="deleteSuccesful">
            <span> Tu Postulación se ha eliminado exitosamente. </span>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-col v-if="desktop && !deleteSuccesful" class="text-center">
            <a
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              class="tway-violet--text roboto"
              v-if="desktop"
              :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
              @click.stop="modalDelete = false"
            >
              VOLVER
            </a>
            <v-btn
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              v-if="desktop"
              rounded
              small
              color="tway-violet"
              class="elevation-2 pa-6 mb-2 white--text roboto"
              @click="deleteTender(deleteTenderId)"
            >
              SI, ELIMINAR POSTULACIÓN
            </v-btn>
          </v-col>
          <v-col v-if="desktop && deleteSuccesful" class="text-center">
            <v-btn
              style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              v-if="desktop"
              large
              rounded
              color="tway-violet"
              class="elevation-2 pa-6 mb-2 white--text roboto"
              @click="(modalDelete = false), (deleteSuccesful = false)"
            >
              ACEPTAR
            </v-btn>
          </v-col>
          <v-row v-if="!desktop && !deleteSuccesful" class="ma-0 pa-0" justify="center">
            <v-col cols="12">
              <v-row class="pa-0 ma-0" justify="center">
                <v-btn
                  style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                  rounded
                  small
                  color="tway-violet"
                  class="elevation-2 pa-6 white--text roboto"
                  @click="deleteTender(deleteTenderId)"
                >
                  SI, ELIMINAR POSTULACIÓN
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row class="pa-0 ma-0" justify="center">
                <a
                  style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                  class="tway-violet--text roboto"
                  v-if="!desktop"
                  :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
                  @click.stop="(modalDelete = false), (deleteSuccesful = false)"
                >
                  VOLVER
                </a>
              </v-row>
            </v-col>
          </v-row>
          <v-col v-if="mobile && deleteSuccesful" class="text-center">
            <v-btn
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              v-if="mobile"
              x-large
              rounded
              color="tway-violet"
              class="elevation-2 pa-6 mb-2 white--text roboto"
              @click="modalDelete = false"
            >
              ACEPTAR
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalCancel" max-width="500">
      <v-card :class="desktop ? 'pa-10' : 'p-2'" style="border-radius: 4px !important">
        <v-card-title
          class="headline mb-3"
          :class="!desktop ? 'pt-8' : ''"
          :style="!desktop ? 'font-size: 20px !important;' : ''"
        >
          <v-row class="ma-0 pa-0" justify="center" v-if="!cancelSuccesful">
            <span class="text-center">¿Realmente quieres cancelar esta Postulación?</span>
          </v-row>
          <v-row class="ma-0 pa-0" justify="center" v-if="cancelSuccesful">
            <v-icon color="#16C62E" size="95"> mdi-check-circle </v-icon>
          </v-row>
        </v-card-title>

        <v-card-text class="headline tway-gray-dark--text mb-4" style="font-size: 18px !important">
          <v-row class="ma-0 pa-0 modal-text" justify="center" v-if="!cancelSuccesful">
            <span> Al cancelar esta Postulación, se eliminarán los datos registrados y no podrás postular </span>
          </v-row>
          <v-row class="ma-0 pa-0 text-center" justify="center" v-if="cancelSuccesful">
            <span> Tu Postulación se ha cancelado exitosamente. </span>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-col v-if="desktop && !cancelSuccesful" class="text-center">
            <a
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              class="tway-violet--text roboto"
              v-if="desktop"
              :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
              @click.stop="modalCancel = false"
            >
              VOLVER
            </a>
            <v-btn
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              v-if="desktop"
              rounded
              small
              color="tway-violet"
              class="elevation-2 pa-6 mb-2 white--text roboto"
              @click="cancelProject(cancelProjectId)"
            >
              SI, CANCELAR POSTULACIÓN
            </v-btn>
          </v-col>
          <v-col v-if="desktop && cancelSuccesful" class="text-center">
            <v-btn
              style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              v-if="desktop"
              large
              rounded
              color="tway-violet"
              class="elevation-2 pa-6 mb-2 white--text roboto"
              @click="(modalCancel = false), (cancelSuccesful = false)"
            >
              ACEPTAR
            </v-btn>
          </v-col>
          <v-row v-if="!desktop && !cancelSuccesful" class="ma-0 pa-0" justify="center">
            <v-col cols="12">
              <v-row class="pa-0 ma-0" justify="center">
                <v-btn
                  style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                  rounded
                  small
                  color="tway-violet"
                  class="elevation-2 pa-6 white--text roboto"
                  @click="cancelProject(cancelProjectId)"
                >
                  SI, CANCELAR POSTULACIÓN
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row class="pa-0 ma-0" justify="center">
                <a
                  style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                  class="tway-violet--text roboto"
                  v-if="!desktop"
                  :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
                  @click.stop="(modalCancel = false), (cancelSuccesful = false)"
                >
                  VOLVER
                </a>
              </v-row>
            </v-col>
          </v-row>
          <v-col v-if="mobile && cancelSuccesful" class="text-center">
            <v-btn
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              v-if="mobile"
              x-large
              rounded
              color="tway-violet"
              class="elevation-2 pa-6 mb-2 white--text roboto"
              @click="modalCancel = false"
            >
              ACEPTAR
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CallForTenderService from "@/services/callForTenderService";
import projectStatusJSON from "@/utils/projectStatus";
import projectApplicationStatusJSON from "@/utils/projectApplicationStatus";
import SubscriptionService from "@/services/subscriptionService";

export default {
  name: "SpecialistProjects",

  data: () => ({
    project: null,
    projects: [],
    initProjects: false,
    projectsContants: [],
    statusSelected: 0,
    tabsDef: [
      {
        title: "Potenciales Proyectos",
        viewType: "LIST",
        filter: (item) =>
          item.status.id === projectStatusJSON.publish.id &&
          item.applicationStatus.id === projectApplicationStatusJSON.noApplication.id &&
          item.name &&
          item.businessGoal &&
          item.proyectGoal &&
          item.period &&
          item.publicationPeriod.startDate.split("T")[0] <= new Date().toISOString().split("T")[0] &&
          item.publicationPeriod.endDate.split("T")[0] >= new Date().toISOString().split("T")[0],
      },
      {
        title: "En proceso de Postulación",
        viewType: "CARDS",
        filter: (item) =>
          item.status.id === 3 &&
          (item.applicationStatus.id === projectApplicationStatusJSON.incompleteApplication.id ||
            item.applicationStatus.id === projectApplicationStatusJSON.inApplication.id ||
            item.applicationStatus.id === projectApplicationStatusJSON.inPublishProcess.id),
      },
      {
        title: "En proceso de Licitación",
        viewType: "CARDS",
        filter: (item) =>
          item.status.id === 4 && item.applicationStatus.id === projectApplicationStatusJSON.inTenderProcess.id,
      },
      {
        title: "Postulaciones Anteriores",
        viewType: "CARDS",
        filter: (item) =>
          item.status.id === 5 &&
          (item.applicationStatus.id === projectApplicationStatusJSON.running.id ||
            item.applicationStatus.id === projectApplicationStatusJSON.finished.id ||
            item.applicationStatus.id === projectApplicationStatusJSON.cancelled.id ||
            item.applicationStatus.id === projectApplicationStatusJSON.failed.id),
      },
    ],
    subscription: {
      plan: {
        name: "",
      },
    },
    planName: null,
    modalCreated: false,
    modalCancel: false,
    modalDelete: false,
    deleteSuccesful: false,
    cancelSuccesful: false,
    cancelProjectId: "",
    deleteTenderId: "",
  }),
  created() {
    this.getCurrentUserSubscription();
    this.getMyProjects();

    let redirectedRequest = this.$route.params.tenderRequestId;
    if (redirectedRequest) {
      this.modalCreated = true;
    }
  },
  methods: {
    getMyProjects() {
      CallForTenderService.getSpecialistsProjects()
        .then((projects) => {
          this.projects = projects.map((p) => {
            return { ...p, show: false };
          });
        })
        .catch((err) => this.$log.error("Unexpected error: ", err))
        .finally(() => {
          this.initProjects = true;
          this.$emit("loading", false);
        });
    },
    getCurrentUserSubscription() {
      SubscriptionService.getCurrentUserSubscription()
        .then((subscription) => {
          this.subscription = subscription;
          this.planName = subscription ? subscription.plan.name : null;
        })
        .catch((err) => this.$log.error(err));
    },
    goToProject(project) {
      this.$router.push(`/project-presentation/${project.id}`);
    },
    goToTracking(project) {
      this.$router.push({
        name: "project-tracking",
        params: { project: project, projectId: project.id },
      });
    },
    detalle(id) {
      CallForTenderService.getLastRequestByProject(id)
        .then((resp) => {
          this.$router.push(`apply-for-project/${resp.id}`);
        })
        .catch((err) => {
          this.$log.error("Unexpected error: ", err);
          this.$router.push({ name: "my-projects" });
        });
    },
    editar(id) {
      CallForTenderService.getLastRequestByProject(id)
        .then((resp) => {
          this.$router.push(`apply-for-project/${resp.id}`);
        })
        .catch((err) => {
          this.$log.error("Unexpected error: ", err);
          this.$router.push({ name: "my-projects" });
        });
    },
    deleteTen(id) {
      this.deleteTenderId = id;
      this.modalDelete = true;
    },
    cancel(id) {
      this.cancelProjectId = id;
      this.modalCancel = true;
    },
    formatDate(dateStr) {
      let formatedDate = "";
      let date = new Date(dateStr);
      if (date) {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        formatedDate = `${day < 10 ? "0" : ""}${day}/${month < 10 ? "0" : ""}${month}/${year}`;
      }
      return formatedDate;
    },
    deleteTender(id) {
      CallForTenderService.getLastRequestByProject(id)
        .then((resp) => {
          CallForTenderService.deleteTender(resp.id)
            .then(() => {
              this.deleteSuccesful = true;
              this.getMyProjects();
            })
            .catch((err) => this.$log.error("Unexpected error: ", err));
        })
        .catch((err) => {
          this.$log.error("Unexpected error: ", err);
          this.$router.push({ name: "my-projects" });
        });
    },
    cancelProject(id) {
      CallForTenderService.getLastRequestByProject(id)
        .then((resp) => {
          CallForTenderService.cancelRequest(resp.id)
            .then(() => {
              this.cancelSuccesful = true;
              this.getMyProjects();
            })
            .catch((err) => this.$log.error("Unexpected error: ", err));
        })
        .catch((err) => {
          this.$log.error("Unexpected error: ", err);
          this.$router.push({ name: "my-projects" });
        });
    },
  },
  computed: {
    projectsInTender() {
      return this.projects.filter((p) => p.flag);
    },
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style scoped>
.main-title {
  font-size: 18.75pt;
  line-height: 24pt;
}

.data-label {
  font-size: 16.5pt !important;
  line-height: 22.5pt !important;
  height: 22.5pt;
}

.text-profile {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10.5pt;
  line-height: 20.25pt;
  color: #666666;
}

.project-list >>> .v-slide-group__content {
  margin-left: 48px !important;
}

.date-label {
  font-size: 9pt;
  line-height: 8.25pt;
  text-align: right;
  color: #999999;
}

.main-img {
  margin-top: 10vh;
}
.tway-header-1 {
  font-weight: 400;
}
.description-test {
  font-weight: 300 !important;
  line-height: 25px !important;
  font-family: "Roboto Condensed", sans-serif !important;
}
.t-tway {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 32px;
}
.tab-text {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
}
.card-text {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #333333;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 10px 0px;
}
.border {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 3px 3px 8px;

  position: static;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  flex: none;
  order: 0;
  align-self: flex-start;
  flex-grow: 0;
  margin: 0px 0px;
}
.bgc {
  background: #e8f5e9;
  border-radius: 4px;
}
.byc {
  background: #fff3e0;
  border-radius: 4px;
}
.brp {
  background: #ffebee;
  border-radius: 4px;
}
.bpp {
  background: #f3e5f5;
  border-radius: 4px;
}
.status-lic {
  position: static;
  left: 3.48%;
  right: 3.48%;
  top: 20%;
  bottom: 20%;

  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #7319d5;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
}
.letter {
  position: static;
  left: 3.11%;
  right: 3.11%;
  top: 20%;
  bottom: 20%;

  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #b71c1c;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
}
.status {
  position: static;
  left: 4.76%;
  right: 4.76%;
  top: 20%;
  bottom: 20%;

  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #f57f17;

  flex: none;
  order: 0;
  align-self: center;
  flex-grow: 0;
  margin: 10px 0px;
}
.status-complete {
  position: static;
  left: 4.76%;
  right: 4.76%;
  top: 20%;
  bottom: 20%;

  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;

  color: #16c62e;

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  flex: none;
  order: 0;
  align-self: center;
  flex-grow: 0;
  margin: 10px 0px;
}

.resumen-text {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  max-height: 72px;
  overflow: auto;
  color: #999999;
}
.border-button-violet {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 12px !important;
}
</style>
